import React from 'react';
import { useDispatch } from 'react-redux';

import { logout } from '../../Redux/actions/auth';

const styles = {
    header: {
        backgroundColor: '#EB6363',
        borderRadius: '0'
    },
    btn_cerrar_sesion: {
        color: '#fff'
    }
}

const HeaderSesion = () => {

	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logout());
	}

    /*<li className="nav-item">
        <a className="nav-link text-white" href="/configuracion">Configuración</a>
    </li>*/

    return (
        <nav className="navbar navbar-expand-lg navbar-light justify-content-between" style={styles.header}>
            <a className="navbar-brand" href="/dashboard">
                <img src="/assets/images/isotipo-blanco.png" className="d-inline-block" alt="" height="25" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-3 mr-auto">
                    <li className="nav-item">
                        <a className="nav-link text-white" href="/dashboard">Inicio</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href="/invitaciones_listado">Invitaciones</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href="/invitados_listado">Invitados</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link text-white" href="/mesas_listado">Mesas</a>
                    </li>
                </ul>
                <div className="form-inline my-2 my-lg-0">
                    <a className="nav-link" onClick={handleLogout} href="/" style={styles.btn_cerrar_sesion}>Cerrar sesión</a>
                </div>
            </div>
        </nav>
    )
}

export default HeaderSesion;