import React from 'react';

import { useLocation } from 'react-router-dom';

const styles = {
    fh5co_cover: {
    	backgroundImage: "url('/assets/images/cover_bg_1.jpg')"
    }
}

const Image = () => {

	const location = useLocation();

    return (
        <div className="fh5co-hero" data-section="home">
			<div className="fh5co-overlay"></div>
			<div className="duidui fh5co-cover text-center video-container" data-stellar-background-ratio="0.5">
				<video autoPlay muted loop playsInline >
					<source src="/assets/images/fondo.mp4" type="video/mp4" />
					Your browser does not support the video tag.
				</video>
				<div className="display-t">
					<div className="display-tc">
						<div className="container">
							<div className="col-md-10 offset-md-1">
								<div className="animate-box">
									<h1>{ location.pathname === '/' ? 'Nuestra boda' : 'Mónica & Daniel' }</h1>
									<h2>{
									        {
									          	'/': 'Mónica & Daniel',
									        }[location.pathname] || 'Nuestra boda'
							      	}</h2>
									{
										location.pathname === '/' ? <p><span>07.12.2024</span></p> : ''
									}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    )
}

export default Image;